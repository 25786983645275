import React from "react"
import PropTypes from "prop-types"

// Heading Link
export const Heading = ({ className, children }) => {
  return (
    <>
      <div className={`heading heading-single ${className}`}>{children}</div>
    </>
  )
}

Heading.propTypes = {
  className: PropTypes.string, // Set optional classes
  children: PropTypes.any,
}

Heading.defaultProps = {
  className: "",
}

// Heading Link
export const HeadingLink = ({ className, children }) => {
  return (
    <>
      <div className={`heading heading-link ${className}`}>{children}</div>
    </>
  )
}

HeadingLink.propTypes = {
  className: PropTypes.string, // Set optional classes
  children: PropTypes.any,
}

HeadingLink.defaultProps = {
  className: "",
}
