import React, { useLayoutEffect, useState, useContext } from "react"
import { nanoid } from "nanoid"
import moment from "moment"

import Layout from "components/layout"
import SEO from "components/seo"

// import { Link } from "components/anti/link/link"
import { Section, Container } from "components/anti/grid/grid"
import { Button } from "components/anti/buttons/buttons"

import { AcfImage } from "components/gutenberg/acf-image"
import { CoreGroup } from "components/gutenberg/core-group"
import { CoreHeading } from "components/gutenberg/core-heading"
import { CoreList } from "components/gutenberg/core-list"
import { CoreParagraph } from "components/gutenberg/core-paragraph"
import { AboutRequest } from "../components/shift/about/request"
// import { CoreShortcode } from "components/shortcode"

//Load Context
import { LangContext } from "../context/lang-context"
import { ResourcesDetailsCover } from "../components/shift/resources/details/cover"
import { HomeResources } from "../components/shift/home/resources"
import { NewLineKind } from "typescript"

import pdf from "../assets/img/common/icon-ic-pdf.png"

const PostsTemplate = ({ pageContext }) => {
  const [id] = useState(nanoid)
  const { lang } = useContext(LangContext)

  const data =
    lang === "EN"
      ? pageContext?.data
      : pageContext?.data?.translation || pageContext?.data

  const blocks = data?.blocks
  const cover = data.featuredImage?.node?.sourceUrl

  // const slug = data.slug
  console.log(pageContext)

  const request =
    lang === "EN"
      ? pageContext.page.aboutPage.request
      : pageContext.page.translation.aboutPage.request ||
        pageContext.page.aboutPage.request

  const newestResources = pageContext?.resources?.nodes
  const categories = data?.categories?.nodes.map(e => e.name).join(", ")
  const downloadpdf = data?.post?.downloadpdf?.pdffile?.mediaItemUrl

  // useLayoutEffect(() => {
  //   // ACF Cover Main conditional
  //   const navbar = document.querySelector(".navbar")
  //   if (blocks && blocks[0] && blocks[0].name !== "acf/cover-main") {
  //     navbar.classList.remove("transparent")
  //     navbar.classList.replace("dark", "light")
  //   }
  // }, [blocks])

  return (
    <Layout>
      {/* <SEO
      // title={data.seo.title}
      // description={data.seo.opengraphDescription} // Experiment
      // image={data.seo.opengraphImage.sourceUrl} // Experiment
      /> */}
      {cover && <ResourcesDetailsCover data={cover} />}
      <Section className="pt-5">
        <Container className="mw-lg">
          <div className="post-header mb-5">
            <h6>{categories}</h6>
            <h1 className="title">{data.title}</h1>
            <div className="d-flex justify-content-between">
              <h6>{moment(data.date).format("MMM DD, YYYY")}</h6>
              {downloadpdf && (
                <Button
                  variant="link-alt"
                  link={downloadpdf}
                  target={downloadpdf}
                >
                  {lang === "EN" ? "Download PDF" : "Unduh PDF"}{" "}
                  <span>
                    <img src={pdf} />
                  </span>
                </Button>
              )}
            </div>
          </div>
          <div className="post-content">
            {blocks &&
              blocks.map((block, i) => {
                switch (block.name) {
                  case "acf/image":
                    return <AcfImage data={block} key={`${id}${i}`} /> // Meant to be used to replace Core Image
                  case "core/group":
                    return <CoreGroup data={block} key={`${id}${i}`} />
                  case "core/heading":
                    return <CoreHeading data={block} key={`${id}${i}`} />
                  case "core/list":
                    return <CoreList data={block} key={`${id}${i}`} />
                  case "core/paragraph":
                    return <CoreParagraph data={block} key={`${id}${i}`} />
                  // case "core/shortcode":
                  //   // prettier-ignore
                  //   return <CoreShortcode data={block} pageContext={pageContext} key={`${id}${i}`} />
                  default:
                    return <div key={`${id}${i}`} />
                }
              })}
          </div>
        </Container>
      </Section>
      <HomeResources
        data={newestResources}
        lang={lang}
        title="Latest Articles"
      />
      <AboutRequest data={request} />
    </Layout>
  )
}

export default PostsTemplate
