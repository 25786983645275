import React from "react"

import { Section, Container } from "components/anti/grid/grid"
import { Button } from "../../anti/buttons/buttons"
import { Slider } from "../../anti/slider/slider"
import { HeadingLink } from "../../anti/type/type"
// import { Link } from "../../anti/link/link"
import { Card } from "../../anti/card/card"
import moment from "moment"

import { useScrollAnim } from "src/components/hooks/hooks"

import imgDefault from "../../../assets/img/common/default.jpg"

export const HomeResources = ({ data, lang, title }) => {
  const [trigger, anim] = useScrollAnim()
  const block = data

  return (
    <Section forwardRef={trigger} className="bg-light resources-content">
      <Container>
        <HeadingLink className="flex-wrap">
          <h2 className={`${anim(1)}`}>{title ? title : "Resources"}</h2>
          <Button variant="link" link="/resources" className={`${anim(2)}`}>
            {lang === "EN" ? "View All Resources" : "Lihat Semua Resource"}
          </Button>
        </HeadingLink>
        <Slider
          visibleInitial={false}
          visibleXlDown={false}
          visibleLgDown={false}
          visibleMdDown={false}
          visibleSmDown={false}
          showInitial={3}
          showXlDown={3}
          showLgDown={2}
          showMdDown={2}
          showSmDown={1.2}
          arrowsInitial={false}
          arrowsLgDown={false}
          arrowsMdDown={false}
          arrowsSmDown={false}
        >
          {block &&
            block.map((data, i) => {
              const dataLang = lang === "EN" ? data : data?.translation || data

              const img = dataLang?.featuredImage
                ? dataLang?.featuredImage?.node?.sourceUrl
                : imgDefault

              const author = dataLang?.author?.node?.name
              const categories = dataLang?.categories?.nodes
                .map(e => e.name)
                .join(", ")
              return (
                <Card
                  variant="boxless"
                  img={img}
                  imgRatio="r-3-2"
                  label={categories}
                  title={dataLang.title}
                  className={`card-resource ${anim(3 + i)}`}
                  link={`/resources/details/${data.slug}`}
                  forwardKey={i}
                >
                  <p className="card-text">{`${moment(dataLang.date).format(
                    "DD MMM YYYY"
                  )} • By ${author}`}</p>
                  <Button variant="link">
                    {lang === "EN" ? "Read More" : "Baca Selengkapnya"}
                  </Button>
                </Card>
              )
            })}
        </Slider>
      </Container>
    </Section>
  )
}
