import React from "react"

import { Cover } from "components/anti/cover/cover"
import { Breadcrumb } from "components/anti/breadcrumb/breadcrumb"

export const ResourcesDetailsCover = ({ data }) => {
  return (
    <Cover
      breadcrumb={<Breadcrumb path={`/resources/details`} />}
      variant="basic"
      theme="dark"
      img={data}
      imgHeight="h-300px h-lg-400px w-100"
    />
  )
}
